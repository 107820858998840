import { useEffect, useState } from 'react';

export const useDebounce = <T>(
  value: T,
  milliSeconds: number,
  onCall?: (value: T) => void,
) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (onCall) onCall(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};

import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type {
  ListSyncErrorsResponse,
  SyncError,
} from '@medsimples/doctor-onboarding-openapi-v2';
import type { DocumentType_Type } from '@medsimples/doctor-shift-manager/lib/generated';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

enum SyncTasks {
  ADD_DOCTOR = 'Inserção do Profissional',
  ADD_DOCTOR_COMPANY_DOCUMENT = 'Inserção de documento Empresa',
  ADD_DOCTOR_DOCUMENT = 'Inserção de documento Profissional',
  ADD_DOCTOR_TO_GROUP = 'Inserção à unidade de Saúde',
}

const DocumentTypes: Record<DocumentType_Type, string> = {
  Degree: 'Certificado de Formação',
  CrmCard: 'Cartão CRM',
  Rg: 'RG',
  Cpf: 'CPF',
  Resume: 'Currículo profissional',
  Other: 'Outros',
  SocialContract: 'Contrato Social',
  Cnpj: 'Cartão CNPJ',
  CNH: 'CNH',
  CertificateOfGoodStanding: 'Certificado de regularidade fiscal',
  ACLSCertificate: 'Certificado ACLS',
  PALSCertificate: 'Certificado PALS',
  ATLSCertificate: 'Certificado ATLS',
  ALSOCertificate: 'Certificado ALSO',
  BLSCertificate: 'Certificado BLS',
  VaccinationCard: 'Carteira de Vacinação',
  NationalCovid19VaccinationCertificate: 'Certificado de Vacinação COVID-19',
  EthicalProfessionalCertificate: 'Certidão ético profissional',
  CertificateOfTaxRegularity: 'Certificado de regularidade fiscal',
  SpecialistPostgraduateDiploma: 'Certificado de Pós-Graduação',
  MunicipalRegistration: 'Inscrição Municipal',
  CART: 'CART',
  PostgraduateDiploma: 'Certificado de Pós-Graduação',
  CardiologistCertificate: 'Certificado de Cardiologista',
  DigitalCertificateOwnershipTerm: 'Certificado digital (termo digital)',
  StateRegistration: 'Inscrição Estadual',
  Contract: 'Contrato',
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  syncErrorsData: ListSyncErrorsResponse;
}

export default function SyncStatusModal({
  isOpen,
  onClose,
  syncErrorsData,
}: Props) {
  const columns: ColumnsType<SyncError> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 80,
    },
    {
      title: 'Etapa',
      dataIndex: 'task',
      key: 'task',
      width: 150,
      render: (_, record) => SyncTasks[record.task],
    },
    {
      title: 'Causa',
      dataIndex: 'error',
      key: 'error',
      width: 200,
      render: (_, record) =>
        record.error?.groupId
          ? `Erro ao tentar adicionar à unidade de saúde ${record.error?.groupId}`
          : record.error?.documentName
            ? `Erro no documento ${record.error?.documentName}`
            : record.error?.documentType
              ? `Erro no documento ${DocumentTypes[record.error?.documentType]}`
              : record.error?.fileName
                ? `Erro no documento ${record.error?.fileName}`
                : 'Causa não mapeada',
    },
  ];

  return (
    <Modal size='3xl' isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`Pendências ativação profissional ID ${syncErrorsData?.professionalId}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table
            rowKey={'cpf'}
            columns={columns}
            dataSource={syncErrorsData?.errors}
            pagination={false}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={onClose}
            colorScheme='red'
          >
            Voltar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

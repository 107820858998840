export default {
  colors: {
    iris: {
      '60': '#A5A6F6',
      '80': '#7879F1',
      '100': '#5D5FEF',
    },
    purple: {
      '50': '#EDEAFB',
      '100': '#CCC3F3',
      '200': '#AC9DEC',
      '300': '#8B77E4',
      '400': '#6B50DD',
      '500': '#351E98',
      '600': '#3B22AA',
      '700': '#2D1980',
      '800': '#1E1155',
      '900': '#0F082B',
    },
    blue: {
      '50': '#DEE9F7',
      '100': '#BDD3F0',
      '200': '#7FA9E1',
      '300': '#3D7DD2',
      '400': '#245799',
      '500': '#15335A',
      '600': '#102846',
      '700': '#0D1E36',
      '800': '#091525',
      '900': '#040911',
      '950': '#020508',
    },
    'blue.light': {
      '50': '#E5F2FF',
      '100': '#CCE5FF',
      '200': '#99CAFF',
      '300': '#66B0FF',
      '400': '#3396FF',
      '500': '#007BFF',
      '600': '#0063CC',
      '700': '#004A99',
      '800': '#003166',
      '900': '#001933',
      '950': '#000C19',
    },
    cyan: {
      '50': '#E5F7FF',
      '100': '#B8E8FF',
      '200': '#8AD9FF',
      '300': '#5CCBFF',
      '400': '#2EBCFF',
      '500': '#00ADFF',
      '600': '#008ACC',
      '700': '#006899',
      '800': '#004566',
      '900': '#002333',
    },
    teal: {
      '50': '#E5FFF7',
      '100': '#B8FFE9',
      '200': '#8AFFDB',
      '300': '#5CFFCD',
      '400': '#2EFFBE',
      '500': '#00D996',
      '600': '#00CC8D',
      '700': '#00996A',
      '800': '#006647',
      '900': '#003323',
    },
    green: {
      '50': '#EBFAF5',
      '100': '#C6F1E4',
      '200': '#A1E8D2',
      '300': '#7CDFC1',
      '400': '#57D5AF',
      '500': '#1A6951',
      '600': '#28A47E',
      '700': '#1E7B5F',
      '800': '#14523F',
      '900': '#0A2920',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    red: {
      '50': '#FDE2E2',
      '100': '#FBC6C6',
      '200': '#F78C8C',
      '300': '#F35353',
      '400': '#EF1A1A',
      '500': '#C00D0D',
      '600': '#990A0A',
      '700': '#730808',
      '800': '#4C0505',
      '900': '#260303',
      '950': '#130101',
    },
    'red.light': {
      '50': '#FFFFFF',
      '100': '#FFFAFA',
      '200': '#FEF5F5',
      '300': '#FEF5F5',
      '400': '#FEF0F0',
      '500': '#FEECEC',
      '600': '#F99090',
      '700': '#F43434',
      '800': '#B80A0A',
      '900': '#5C0505',
      '950': '#300303',
    },
    orange: {
      '50': '#FFFAF0',
      '100': '#FEEBC8',
      '200': '#FBD38D',
      '300': '#F6AD55',
      '400': '#ED8936',
      '500': '#DD6B20',
      '600': '#C05621',
      '700': '#9C4221',
      '800': '#7B341E',
      '900': '#652B19',
    },
  },
};
